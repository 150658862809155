.activite{
    .content{

        flex-direction: column;
    }
    .modalAc{
        width: 100%;
        max-width: 500px;
        h5{
            font-weight: 600;
        }
    }
}