.payinfo {
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .details {
        display: flex;
        flex-direction: column;
        gap: 20px;

        width: 100%;
        .cleft{
            display: flex;
            justify-content: center;
        gap: 10px;}
        .card {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 10px;
            border: 1px solid $grey-border;
            padding: 10px;
            background-color: white;
            border-radius: 9px;
            align-items: center;
            justify-content: space-between;
            align-items: center;

        }

        .plan {
            display: flex;
            flex-direction: row;
            gap: 10px;
            width: 100%;
            align-items: center;
            border: 1px solid $grey-border;
            border-radius: 9px;
            padding: 10px;

            .plan__name {
                font-size: 16px;
                font-weight: 500;
            }

        }


    }
    .clientlist {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        padding: 12px 14px;
        flex-direction: column;
        gap: 7px;
        border-radius: 9px;
        border: 1px solid $grey-border;
        background: $grey-back;
        overflow: scroll;
        flex: 1 1;

        .client {
            padding: 10px 20px;
            justify-content: left;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            display: flex;
            background-color: white;
            border-radius: 9px;
            transition: 200ms ease-in-out;
            border: 1px solid $grey-border;
            cursor: pointer;

            svg{
                width: 0px;
                transition: 200ms ease-in-out;
            }
            .nom {
                align-self: stretch;
                display: flex;
                flex: 1 0;
                align-content: center;
                align-items: center;
                background-color: transparent;
            }
            &.actif{
                border: 1px solid #006963;
                svg{
                    width: 10px;
                    transition: 200ms ease-in-out;
                }
            }
            &:hover{
                border: 1px solid #006963;
            }
        }
    }
 
}