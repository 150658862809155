// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;800&family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Abhaya Libre';
  src: url('../fonts/Abhaya/AbhayaLibre-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

//police et taille
$font-1: "Inter", sans-serif;
$font-2: 'Abhaya Libre', serif;
$min-font-size-p: 15px !default;
$max-font-size-p: 17px !default;
$min-font-size-h1: 27px !default;
$max-font-size-h1: 60px !default;
$min-font-size-h2: 22px !default;
$max-font-size-h2: 42px !default;
$min-font-size-h3: 21px !default;
$max-font-size-h3: 28px !default;
$min-font-size-h4: 18px !default;
$max-font-size-h4: 24px !default;
$min-font-size-h5: 15px !default;
$max-font-size-h5: 18px !default;
$min-font-size-h6: 12px !default;
$max-font-size-h6: 14px !default;
$min-button-font-size: 15px !default;
$max-button-font-size: 20px !default;


h1,
h2,
h3,
h4,
h5 {
  text-wrap: balance;
}

h3 {
  font-size: $max-font-size-h3;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: $min-font-size-h1;
  }

  p {
    font-size: $min-font-size-p;
  }
}

p {
  text-wrap: pretty;
}

$dark: #002927;
$light: #C5FFFE;
$color-1: #006963;
$color-2: #25AAA5;
$color-alert: #FF4D00;
$color-back-alert: #FFE2D1;
$color-alert-dark: #943500;
$color-back-success: #92FFB1;
$grey-light: #FCFCFC;
$grey-strong: #D9D9D9;
$grey-back: #F2F2F2;
$grey-border: #D9D9D9;
$grey-writted: #787878;
$grey-selection: #D8D8D8;
$grey-coloured: #dce6e6;
$color-button: #282828;
$white: rgb(255, 255, 255);
$blackL: #524D4D;
$blackT: rgba(0, 0, 0, 0.277);
;

:root {
  --big: calc((100vw / 12) * 1);
  --mid: calc((100vw / 12) * 0.5);
  --small: calc((100vw / 12) * 0.25);
  --xsmall: calc((100vw / 12) * 0.15);
  --max-w: 1440px;
}

@media screen and (max-width: 500px) {
  :root {
    --big: calc((100vw / 12) * 4);
    --mid: calc((100vw / 12) * 2);
    --small: calc((100vw / 12) * .5);
    --xsmall: calc((100vw / 12) * .5);
  }
}

$big: var(--big);
$mid: var(--mid);
$small: var(--small);
$xsmall: var(--xsmall);
$max-w: 1440px;



@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $color-2;
}

::-webkit-scrollbar-track {
  background: transparent;
}

// For Internet Explorer
* {
  scrollbar-face-color: $color-2;
  scrollbar-track-color: $grey-selection;
}

html {
  scroll-behavior: smooth;
}


body {
  font-family: $font-1;
  background-color: $white;
  color: black;
  overflow-y: scroll;
  overflow-x: hidden;
}



.page {
  display: flex;
  height: 100vh;
}

.content {
  display: flex;
  align-items: flex-start;
  height: 100%;
  flex: 1 1;
  background-color: $grey-light;
  width: 100%;
  overflow: auto;

  .non-active {
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      display: flex;
      z-index: 700;
      align-self: center;
      justify-self: center;
      padding: 50px;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.83);
    }

    &::after {
      content: "En attente de confirmation...";
      position: absolute;
      font-size: 24px;
      padding: 20px;
      height: -moz-fit-content;
      align-self: center;
      justify-self: center;
      z-index: 800;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      width: fit-content;
      border-radius: 9px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
    }
  }
}


.sousmenupro {
  width: calc(100% - $small - $small);
  border-radius: 5px;
  border-bottom: 1px solid $color-1;
  margin-left: $small ;
  margin-right: $small;
  margin-top: $xsmall ;
  display: flex;

  a {
    display: flex;
    padding: 5px 10px;
    border-left: 1px solid $color-2;
    justify-content: center;
    align-items: center;
    gap: 2px;

    &:first-child {

      border-radius: 5px 0px 0px 5px;
    }

    &.active {
      background: $color-1;
      color: white;
    }

    &:hover {
      background-color: $color-2;
      color: white;
    }
  }
}

.dynamicswitch {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  background-color: $grey-back;
  border-radius: 9px;
  padding: 5px;
  gap: 5px;
  margin-bottom: 10px;

  p {
    font-size: 16px;
    font-weight: 600;
  }

  .btn {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-radius: 50px;
    justify-content: center;
    transition: 200ms ease-in-out;
    color: $color-1;

    &.active {
      background-color: $dark;
      color: white;
      transition: 200ms ease-in-out;
    }

    &:hover {
      background-color: $color-1;
      color: white;
      transition: 200ms ease-in-out;
    }

    cursor: pointer;
  }
}

.flex-h {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;

  .left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    justify-content: start;
    padding: $small;
    overflow: scroll;

    &.sep {
      border-right: $grey-selection 1px solid;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    height: 100%;
    justify-content: start;
    align-items: center;
    padding: $small;
    overflow: scroll;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    position: relative;
  }
}

.sgap {
  gap: 15px;
}

.sgap-10 {
  gap: 10px;
}

.sgap-5 {
  gap: 5px;
}


.sgap-20 {
  gap: 20px;
}

.sgap-30 {
  gap: 30px;
}

.sgap-40 {
  gap: 40px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: $color-1;
  transition: 150ms ease-in-out;
  background-color: white;

  p {
    font-size: 16px;
  }
}

a:hover {
  transition: 150ms ease-in-out;
  cursor: pointer;
}

p {
  font-size: 14px;
  font-weight: 500;
}

.spefont {
  font-family: $font-2 ;
}

button {
  cursor: pointer;
}

.strong {
  font-weight: 600;
  padding: 1px;
  font-size: 16px;
}

.desc {
  color: $grey-writted;
}

.grp {
  width: 100%;
  display: flex;
  gap: 0px 5px;
  flex-wrap: wrap;
  align-items: center;
}

.justify {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span {
  width: 100%;
  height: 1px;
  align-self: center;
  background-color: $grey-strong;

  &.verti {
    height: 10px;
    width: 1px;
    background-color: $grey-strong;
  }

  &.vertibig {
    height: 100%;
    width: 1px;
    background-color: $grey-strong;
  }
}


section,
.section {
  display: flex;
  justify-content: center;

  .contentfront {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    max-width: $max-w;
  }
}

h1 span,
h2 span {
  background-color: transparent;
}


.iconl {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    white-space: nowrap;
  }
}

.btn-base {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: transparent .5px solid;
  border-radius: 0px;
  padding: 2px;
  transition: 150ms ease-in-out;
  border-radius: 5px;
  height: fit-content;

  svg {
    width: 9px;
  }

  p {
    color: $color-1;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }

  .tac {
    text-align: center;
  }

  &.danger {
    p {
      color: $color-alert;
    }

    &:hover {
      background-color: $grey-back;
      cursor: pointer;
    }
  }

  &:hover {
    border-color: $grey-writted;
    cursor: pointer;
  }

  &.rond {
    border-radius: 100px;
    padding: 15px;
    aspect-ratio: 1.1;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

}

.btn-simple {
  border-radius: 9px;
  background: $color-1;
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }

  &:hover {
    background: $dark;
  }

  svg {
    width: 15px;
  }
}

.nouvMdp {
  width: 100%;
}

.btn-pro {
  background: $color-1;
  border-radius: 9px;
  background: $color-1;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  transition: 150ms ease-in-out;

  p {
    color: white;
    font-weight: 500;
    font-size: 16px;
    font-family: "Inter", sans-serif;
  }

  &:hover {
    background: $dark;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }

  &.danger {
    background: $color-alert;

    p {
      color: white;
    }

    &:hover {
      background-color: $color-alert-dark;
    }

  }

  &.scnd {
    background: $grey-back;

    p {
      color: black;
      font-family: "Inter", sans-serif;
    }

    &:hover {
      background: $grey-selection;
    }


     

    
  }

  &.off { 
    filter: grayscale(50%); // Applique un filtre en niveaux de gris */
    pointer-events: none;
    p {
      color: rgb(148, 148, 148);
      font-family: "Inter", sans-serif;
    }
  }

  &.full {
    margin-top: $xsmall;
    width: 100%;
  }

  .loadingAnim {
    svg {
      height: 20px;
      fill: #ffffff;
    }
  }
}

.btn-front {
  background: $color-1;
  border-radius: 50px;
  display: flex;
  padding: 7px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  transition: 150ms ease-in-out;
  position: relative;
  overflow: hidden;

  &.spe {
    background: $color-2;
    padding: 5px 5px 5px 15px;

    span {
      position: absolute;
      width: calc(100%);
      height: 100%;
      left: -20px;
      transform: skew(20deg);
      background: $color-1;
      transition: 150ms ease-in-out;
    }

    .icon {
      padding: 5px;
      border-radius: 50px;
      background-color: white;
      width: 30px;
      height: 30px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 150ms ease-in-out;
      aspect-ratio: 1;
    }

    &:hover {
      background: none;
      cursor: pointer;
      transition: 150ms ease-in-out;

      span {
        background-color: $dark;
        left: -10px;
        width: 150%;
        transition: 150ms ease-in-out;
      }
    }

    p {
      padding: 5px;
      color: white;
      z-index: 2;
      font-family: "Inter", sans-serif;
    }

    &.active {
      background: $dark;

      span {
        background-color: $dark;
      }
    }
  }

  &.black {
    background-color: $blackL;
  }

  p {
    color: white;
    z-index: 2;
    font-size: 16px;
    white-space: nowrap;
    font-family: "Inter", sans-serif;
  }

  &:hover {
    background: $dark;
    cursor: pointer;
    transition: 150ms ease-in-out;
  }
}

.fin {
  position: relative;

  &:after {
    position: absolute;
    top: 100%;
    left: 100%;
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.21) 13.15%, rgba(255, 255, 255, 0.14) 42.52%, rgba(255, 255, 255, 0.00) 97.14%);
  }
}

.dnone {
  display: none !important;
}

.modal {
  position: fixed;
  background: rgba(255, 255, 255, 0.71);
  top: 0;
  left: 0;
  height: 100%;
  min-width: 100vh;
  width: 100vw;
  z-index: 10000;
  overflow: scroll;

  .registerPro {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .proEvent {
    width: 100%;

    button {
      width: -moz-fit-content;
      width: fit-content;
      margin: inherit;
    }
  }

  .formheader {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    padding: 10px;
    margin-bottom: 15px;
    background: white;
    border-bottom: #D9D9D9 1px solid;
    z-index: 10;
    min-width: 500px;
  }

  .modal-content {
    max-width: 800px;
    width: fit-content;
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 15px;
    border: 1px solid $grey-writted;
    min-width: 500px;
    background: #FFF;
    box-shadow: 15px 31px 40px 0px rgba(0, 0, 0, 0.37);
    margin-top: calc(140px + 2vh);
    margin-bottom: 50px;
  }

  .confirmation,
  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .result {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .illu {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      border: $color-2 1px solid;
      padding: 15px;
      border-radius: 9px;

      &.alert {
        border: $color-alert 1px solid;
      }

      svg {
        width: 70px;
        max-width: 70px;
      }

      .txt {
        display: flex;
        flex-direction: column;
        gap: 5px;
        text-align: left;

        p {
          font-size: 18px;
        }
      }
    }
  }

  &.open {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &.closed {
    display: none;
  }

  .btns {
    padding-top: 15px;

    a,
    button {
      width: 100%;
    }
  }

  button {
    width: fit-content;
    margin: auto;

    &.inactive {
      filter: grayscale(100%); /* Applique un filtre en niveaux de gris */
      cursor: not-allowed;
    }
  }
}



.alerttop {
  width: 100%;
  right: 40px;
  padding: 15px 10px;
  background-color: $color-back-success;
  position: fixed;
  z-index: 11000;
  width: fit-content;
  border-radius: 9px;
  top: 30px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
  transition: 200ms ease-in-out;

  &.open {
    display: block;

  }

  &.alert {
    background-color: $color-back-alert;

  }

  &.closed {
    transition: 200ms ease-in-out;
    opacity: 0;
    transform: translateY(calc(-100% + 30px));
  }

  .btn-base {
    padding: 5px;

    svg {
      width: 15px;
    }
  }

  .alert-content {
    width: 90%;
    margin: auto;
    max-width: $max-w;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 9px;

    .txt {
      display: flex;
      gap: 15px;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 16px;
      }

      svg {
        width: 25px;
      }


    }
  }

  svg {
    width: 18px;
  }



  .btns {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    a,
    button {
      width: 100%;
    }
  }
}

.tsave {
  display: flex;
  justify-content: space-between;
}


.filtre {
  display: flex;
  gap: 5px 10px;

  a {
    color: black;
    background: white;
    font-size: 16px;
    padding-left: 5px;

    &.active,
    &:hover {
      font-weight: 600;
      color: $color-1;
    }
  }

  &.verti {
    flex-direction: column;
  }

  span {
    align-self: center;
  }
}

.loading {
  height: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;

  span {
    background: linear-gradient(270deg, rgba(217, 217, 217, 0.00) 0%, $grey-border 54.27%, rgba(255, 255, 255, 0) 100%);
    animation-name: loadinganim;
    transform: translateX(50%);
    animation-duration: 1s;
    animation-iteration-count: infinite;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.loadingAnim {
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  grid-column: 1 / -1;

  svg {
    height: 70px;
    fill: $color-1
  }
}

.formheader {
  display: flex;
  align-items: center;
  position: sticky;
  top: 110px;
  padding: 10px;
  background: white;
  border-bottom: $grey-border 1px solid;
  z-index: 10;

}

.step-indicator {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  flex: 1;

  svg {
    width: 12px;
  }

  .step-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;

    p {
      color: $grey-writted;
    }

    &.active {
      p {
        color: $dark;
      }
    }
  }
}


.dropzone.drag-over {
  /* styles lorsque un fichier est survolé */
  border: 1px dashed $color-1 ;
  /* exemple de style */
  background-color: rgba(0, 0, 0, 0.1);
  /* exemple de style */
}

.itemnotif {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  gap: 15px;
  border-radius: 9px;
  background-color: $color-back-success;
  border: solid .5px $grey-selection;

  &.link {
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.086));

      border: .5px solid $color-1;
    }
  }

  p {
    width: 100%;
  }

  svg {
    width: 32px;
  }

  &.alert {
    background-color: $color-back-alert;
  }
}


.notifs {
  border-radius: 29px;
  padding: 10px;
  gap: 10px;
  display: flex;
  border: 1px solid #FF5E00;
  background: #FFF;
  transition: 200ms ease-in-out;
  align-items: center;
  position: relative;

  p {
    display: none;
  }

  svg {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: 200ms ease-in-out;
  }

  &:hover {
    transition: 200ms ease-in-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;

    svg {
      filter: none;
      transition: 200ms ease-in-out;
    }
  }

  &.nouv {
    border-radius: 9px;

    p {
      display: inline;
    }

    svg {
      transform: rotate(25deg);
    }

    &:hover {
      svg {
        transform: rotate(0deg);
      }

    }
  }

  .nbnotif {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    border-radius: 50px;
    padding: 3px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(40%, 40%);

    p {
      display: block;
    }
  }
}

.notifsac {
  display: flex;
  gap: 18px;



  .alert {
    background-color: $color-back-alert;
  }

  .link {
    cursor: pointer;
  }
}

@keyframes loadinganim {
  0% {
    right: 30%;
  }

  50% {
    right: 70%;
  }

  100% {
    right: 30%;
  }
}

.confirmation {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 500;
  }

  .illu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: $color-2 1px solid;
    padding: 15px;
    width: fit-content;
    border-radius: 9px;

    &.alert {
      border: $color-alert 1px solid;
    }

    svg {
      width: 70px;
      max-width: 70px;
    }

    .txt {
      display: flex;
      flex-direction: column;
      gap: 5px;

      p {
        font-size: 18px;
        overflow: hidden;
        /* Masque le texte qui déborde */
        text-overflow: ellipsis;
      }
    }
  }

  .infos {
    display: flex;
    gap: 15px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .grp {
      flex-wrap: nowrap;
      width: fit-content;
      text-align: center;
    }
  }

  .grp {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.cookieConsent {
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  gap: 10px;
  right: 20px;
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 15px;
  text-align: left;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);

  .head {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    gap: 10px;
    justify-content: center;
    align-items: center;

    svg {
      width: 30px;
    }
  }

  .form-group {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    padding: 15px;

    label {
      font-size: 18px;
      font-weight: 500;
    }

    &:hover {
      background-color: $grey-back;
      border-radius: 9px;
    }
  }

  .btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &.small {

    cursor: pointer;
    gap: 0px;
    width: 50px;
    height: 50px;
    padding: 15px;

    .head {
      h4 {
        display: none;

      }
    }

    .btns {
      pointer-events: none;
    }
  }

  &.small>*:not(.head) {

    margin-left: 50%;
    opacity: 0;
  }
}

.simpleswitch {
  display: flex;
  gap: 5px;
  padding: 5px;
  border-radius: 50px;
  background-color: $blackL;
  transition: 200ms ease-in-out;
  height: 25px;
  justify-content: start;
  cursor: pointer;
  width: 50px;
  border-radius: 50px;
  transition: ease-in 400ms;
  border: 1px solid $grey-border;
  position: relative;

  span {
    width: 50%;
    border-radius: 50px;
    left: 0;
    height: 100%;
    transition: ease-in 200ms;
    position: absolute;
    background-color: transparent;

    &::after {
      content: " ";
      position: absolute;
      width: 90%;
      height: 90%;
      border-radius: 50%;
      background-color: $white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: ease-in 200ms;

    }
  }

  &.active {
    transition: ease-in-out 200ms;
    background-color: $color-1;

    span {
      transform: translateX(100%);
      transition: ease-in-out 200ms;
    }
  }

  &:hover {
    transition: ease-in-out 100ms;
    border: 1px solid $color-1;
  }


}

.rdvmod{
  display: flex;
  flex-direction: column;
  gap: 10px; 

 

}

.fourofour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100vh;

  h1 {
    font-size: 70px;
    font-weight: 600;
  }

  p {
    font-size: 20px;
    font-weight: 500;
  }

  a {
    font-size: 20px;
    font-weight: 500;
    color: $color-1;
    text-decoration: underline;
  }

  .contentFront {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

}


@media screen and (max-width: 1024px) {
  .modal {
    min-width: inherit;
    height: fit-content;
    z-index: 100000;

    &.open {
      align-items: flex-start;
      height: 100%;
    }

    .modal-content {
      margin-top: 20px;
      padding: 10px;
      width: fit-content;
      min-width: 500px;
      overflow: none;
      height: fit-content;
      margin-bottom: 0px;
    }
  }

  .formheader {
    top: 70px;
  }
}

.mobilevisible {
  display: none!important;
}

.mobilenotvisible {
  display: inherit!important;
}



@media screen and (max-width: 768px) {

  form .line.fs{
    grid-column: 1 / -1;
    padding: 0px!important;
    .form-group {
      grid-column: span 1;
    }
  }

.mobilevisible {
  display: inherit!important;
}

.mobilenotvisible {
  display: none!important;
}

  .sousmenupro{
    margin-top: 20px;
    margin-bottom: 20px;
    overflow-x: scroll;
    a{
      white-space: nowrap;
    }
  }

  .content {
    width: 100%;
  }

  .modal {
    min-width: inherit;
    height: fit-content;
    z-index: 100000;

    &.open {
      align-items: flex-start;
      height: 100%;
    }

    .modal-content {
      margin-top: 20px;
      padding: 10px;
      width: 100vw;
      overflow: none;
      height: fit-content;
      min-width: inherit;
      margin-bottom: 0px;

    }
  }

  .step-indicator {
    .step-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;

      p {
        display: none;
      }

      &.active {
        p {
          color: $dark;
          display: block;
        }
      }
    }
  }
}



.fourofour {
  h1 {
    font-size: 50px;
  }

  p {
    font-size: 16px;
  }

  a {
    font-size: 16px;
  }
}

.cookieConsent {
  right: 0;
  bottom: 0;

  &:not(.small) {
    max-height: 100%;
    overflow: scroll;
  }

  &.small {
    right: 10px;
    bottom: 10px;
  }

  .btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

.btn-front {
  padding: 10px 15px;

  p {
    font-size: 14px;
  }

  &.spe {
    padding: 7px 7px 7px 15px;

    p {
      font-size: 14px;
      padding: 0px;
    }

    .icon {
      padding: 5px;
      width: 25px;
      height: 25px;
    }
  }

}

.btn-pro {
  padding: 10px 15px;

  p {
    font-size: 14px;
  }
}

.pdfView {
  height: 100vh;

  embed {
    width: 100%;
    height: 100%;
  }
}