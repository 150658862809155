.rate{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $small;
    flex-direction: column;
    .rdvdisp{
        min-width: 330px;
    }
    .form{
        
        gap: 15px;
        .raterow{
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-left: 10px;
            border-radius: 9px;
            background-color: $grey-light;
            border-bottom: 1px solid $grey-border;
            p {
                text-align: left;
            }
            span{
                background: white;
            } 
        }
        .starimg{
            width:45px;
            height: 45px;
            margin: 0 5px;
            transition: opacity 0.2s ease-in-out;
            border-radius: 9px; // Transition de 0.2 secondes sur l'opacité
            opacity: .4;
            padding: 5px;
            &.active{
                opacity: 1;
                transition: opacity 0.2s ease-in-out; 
            }
            &:hover{
                transition: opacity 0.2s ease-in-out; 
                background-color: white;
                
            }
        }
    }
    .actions{
        grid-column: 1/-1;
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        grid-column: span 2;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        .full{
            grid-column: 1/-1;
        }
        button{
            width: 100%;
        }
    }
   

}