.headerpro {
    width: 100%;
    height: 55px;
    background-color: $grey-selection;
    display: flex;
    padding: 20px 30px;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 18px;

    .titre {
        flex: 1 0 0;
        color: #000;
        font-weight: 600;
        font-size: 24px;
    }
    @media screen and (max-width:768px ) {  
        width: calc(100% - 55px) ;
        margin-left: 50px; 
        padding: 5px;
        height: 50px;
        .titre {
            margin-left: 10px;
            font-size: 20px;
        }

    }

}