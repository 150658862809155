.availavlecal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    border: 2px solid $grey-coloured;
    border-radius: 9px;
    overflow: hidden;
    background-color: $grey-back; 
    cursor: pointer;
    .en-cours-banner{    
        color: $color-1;
        font-size: 16px;
        font-weight: 400;
        font-weight: 700;
        width: 100%;
        text-align: left;
    }
    &.active{
        border: 2px solid $color-2;
    
    }
    &.open{
       background-color: white; ;
       cursor: default; 
       border: 2px solid $color-2;
      .top .setdate {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding:10px 10px 0px 10px;
    }
    }

    &.isEnCours{ 
        .top{ 
            padding: 3px;
            .title{
                padding: 10px 10px 0px 10px; 
            }
            .btn-pro{
                height: 100%;
            }
    }
    }
    svg{
        width: 20px;
    }
    .top{
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 3px;
        .setdate{
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 10px;
            .setdate__icon{
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: #f5f5f5;
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
            input{
                font-size: 16px;
                font-weight: 500;
            }
        } 
        .title{
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
        }
        .spdat{
            width: 2px;
            height: 30px;
            background: $grey-coloured;
        }
        .date-picker-input{
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 5px;
            border:none;
            background: white;
            border: $color-1 1px solid;
            border-radius: 5px;
            cursor: pointer;
            input{
                width: fit-content;

                font-size: 16px;
                font-weight: 500;
                width: 90px;
            } 
        }
    }
    .availablecal__content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .availablecal__content__item{
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            .availablecal__content__item__icon{
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: #f5f5f5;
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
            .availablecal__content__item__text{
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .content{
        border-radius: 7px;
        overflow: hidden; 
        display: flex;
        flex-direction: column;
        gap: 10px;
        .danger{
            width: 100%;
            padding: 5px;
        }
    }
}