.accueil {

    .imgmobile {
       display: none;
    }

    h2 {
        grid-column: 1 / -1;
        font-size: 42px;
        font-weight: 400;

        span {
            font-size: 58px;
            font-weight: 900;
        }
    }

    h3 {
        font-size: 22px;
        font-weight: 600;
    }

    .hero {
        margin-top: 120px;

        .contentfront {
            padding-top: $small;
            gap: $xsmall;
        }

        h1 {

            grid-column: 1 / -1;
            font-size: 42px;
            font-weight: 400;
            margin-bottom: 15px;
            z-index: 2;

            span {
                font-size: 58px;
                font-weight: 900;
            }
        }

        .txt {
            grid-column: 1 / 4;
            display: flex;
            flex-direction: column;
            gap: 16px;

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 5px 7px;

                li {
                    padding: 2px 5px;
                    border: 1px solid $color-2;
                    color: $color-1;
                    border-radius: 50px;
                }
            }

            a {
                width: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 18px;
                }

                svg {
                    transition: 150ms ease-in-out;
                }

                &:hover {
                    svg {
                        transform: translateY(5px);
                        transition: 150ms ease-in-out;
                    }
                }
            }

            .illu {
                margin-top: 20px;
            }
        }

        .imghero {
            grid-column: 5 / 13;
            height: 100%;
            display: flex;
            gap: $small;
            position: relative;

            .back {
                position: absolute;
                left: 50%;
                bottom: 60%;
            }

            div {
                position: relative;
                width: 33%;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    aspect-ratio: 3/4;
                }



                button {
                    position: absolute;
                    bottom: -15px;
                    right: 15px;
                }

                .filtre {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 49.48%, #002927 100%);
                    height: 100%;
                    width: 100%;
                    top: 0;
                    position: absolute;
                }

                &:hover {
                    cursor: pointer;

                    .spe {
                        background: none;
                        cursor: pointer;
                        transition: 150ms ease-in-out;

                        span {
                            background-color: $dark;
                            left: -10px;
                            width: 150%;
                            transition: 150ms ease-in-out;
                        }
                    }

                }
            }

            .catimg2 {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .catimg1,
            .catimg3 {
                margin-bottom: 30px;
            }
        }

    }

    .bandeau {
        background-color: $color-2;
        margin-top: $mid ;

        .contentfront {
            display: flex;
            justify-content: center;
            gap: $small;
            padding: $xsmall;

            div {
                background-color: $white;
                display: flex;
                gap: $xsmall;
                align-items: center;
                border-radius: 9px;
                padding: 10px 24px 10px 10px;

                p {
                    font-size: 16px;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    .section1 {
        margin: $mid 0;

        .contentfront {
            gap: $small;


        }

        .img {
            grid-column: 1 / 6;
            position: relative;
            height: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .back {
            grid-column: 6 / 7;
            position: relative;
            height: 100%;

            svg {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 50%;
                height: 100%;
            }
        }

        .txt {
            padding: $small 0;
            grid-column: 7 / -1;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            align-items: center;
            gap: $small;

            .item {
                display: flex;
                gap: 20px;
                padding: 15px;
                border-radius: 9px;
                border: 1px solid $grey-light;
                background: #FFF;
                box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);

                .spe {
                    color: #006963;
                    font-family: Abhaya Libre;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .txtit {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                }
            }

            button {
                margin-top: $xsmall;
                grid-column: 3 / -1;
                width: fit-content;
            }

            .it1 {
                grid-column: 1 / -3;
            }

            .it2 {

                grid-column: 2 / -2;
            }

            .it3 {
                grid-column: 3 / -1;
            }
        }
    }

    .section2 {
        padding: $mid 0;
        background-color: $grey-back;

        .contentfront {

            gap: $small;

            .pro-grid {
                grid-column: 1 / -1;
                grid-template-columns: repeat(4, 1fr);
                gap: $xsmall;
                display: grid;

            }
        }
    }

    .section3 {
        padding: $mid 0;

        .contentfront {
            gap: $small;

            .imgs {
                grid-column: 1 / 6;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 1fr);
                display: grid;
                gap: 15px;
                height: 100%;

                div {
                    position: relative;
                    height: 100%;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .img1 {

                    grid-column: 1 / 2;
                    grid-row: 1/2;
                }

                .img2 {

                    grid-column: 1 / 2;
                    grid-row: 2/3;
                }

                .img3 {

                    grid-column: 2 / 3;
                    grid-row: 1/3;
                }
            }
        }


        .info {
            grid-column: 7 / -1;
            padding: $small 0;

            .profe {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                .item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    padding: 5px 10px;
                    border: 1px solid $color-2;
                    border-radius: 9px;

                    svg {
                        width: 40px;
                    }

                    p {
                        font-weight: 600;
                        color: black;
                    }
                }
            }

            .argu {
                margin: $small 0;
                display: flex;
                flex-direction: column;
                gap: 15px;
                justify-content: start;

                .item {
                    display: flex;
                    gap: $xsmall;

                    .svg {
                        max-width: 40px;
                        display: flex;
                        justify-self: center;
                        align-items: center;

                        svg {
                            width: 100%;
                        }
                    }

                    .txtit {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                }

                span {
                    width: 50%;
                    height: 1px;
                    background-color: $color-1;
                    align-self: flex-start;
                }
            }
        }
    }

    .section4 {
        padding: $mid 0;
        background-color: $light;

        .contentfront {
            gap: $small;
            display: flex;
            flex-direction: column;

            h2 {
                font-size: 32px;

                text-align: center;
            }

            h3 {
                font-size: 24px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

}

//ajoute les parametres pour le responsive : 
@media screen and (max-width: 1024px) {
    .accueil {

        

        .imgmobile {
            grid-column: 1 / -1;
            height: 20vh;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
    
            img {
                width: 32%;
                object-fit: cover;
            }
    
            margin-bottom: $small;
        }
        .hero {
            margin-top: 90px;
            height: fit-content;
            margin-bottom: 90px;

            h1 {
                z-index: 2;
                grid-column: 1 / -1;
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 15px;
                text-align: center;

                br {
                    display: none;
                }

                span {
                    font-size: 38px;
                    font-weight: 900;
                }
            }

            .txt {
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .illu {
                    margin-top: 20px;
                }
            }

            .imghero {
                display: none;
            }
        }

        .bandeau .contentfront {
            flex-direction: column;
        }

        .section1 {
            .img {
                grid-column: 1 / -1;
                position: relative;
                height: 200px;
            }

            .txt {
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                gap: 25px;

                .item {
                    padding: 15px;
                    border-radius: 9px;
                    border: 1px solid $grey-light;
                    background: #FFF;
                    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
                }

                button {
                    grid-column: 1 / -1;
                }
            }
        }

        .section3 {
            .contentfront {
                .imgs {
                    grid-column: 1 / -1;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    display: grid;
                    gap: 15px;
                    height: 200px;

                    div {
                        height: 100%;
                    }
                }

                .info {
                    grid-column: 1 / -1;
                    display: flex;
                    flex-direction: column;

                    .profe {
                        display: none;
                    }

                    .argu {
                        margin-bottom: 35px;

                        .item {
                            flex-direction: column;
                            gap: $xsmall;
                        }
                    }
                }
            }
        }

        .section2 {
            padding: $mid 0;
            background-color: $grey-back;

            .contentfront {

                gap: $small;

                .pro-grid {
                    grid-column: 1 / -1;
                    grid-template-columns: repeat(2, 1fr);
                    gap: $xsmall;
                    display: grid;

                    @media screen and (max-width: 500px) {

                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .accueil {

        .section1, .section2, .section3, .section4 {
            padding: 30px 0;
            margin: 0;
        }
        h2 {
            grid-column: 1 / -1;
            font-size: 32px;
            font-weight: 400;

            span {
                font-size: 38px;
                font-weight: 900;
            }
        }

        .hero h1 {
            z-index: 2;
            grid-column: 1 / -1;
            font-size: 28px;
            font-weight: 400;
            margin-bottom: 15px;
            text-align: left;
        }



        .bandeau .contentfront {
            flex-direction: column;
        }

        .section1 {
            .img {
                grid-column: 1 / -1;
                position: relative;
                height: 200px;
            }

            .txt {
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                gap: 25px;

                .item {
                    padding: 15px;
                    border-radius: 9px;
                    border: 1px solid $grey-light;
                    background: #FFF;
                    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
                }

                button {
                    grid-column: 1 / -1;
                }
            }
        }

        .section3 {
            .contentfront {
                .imgs {
                    grid-column: 1 / -1;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    display: grid;
                    gap: 15px;
                    height: 200px;

                    div {
                        height: 100%;
                    }
                }

                .info {
                    grid-column: 1 / -1;

                    .profe {
                        display: none;
                    }

                    .argu {
                        .item {
                            flex-direction: column;
                            gap: $xsmall;
                        }
                    }
                }
            }
        }

        .section2 {
         
            background-color: $grey-back;

            .contentfront {

                gap: $small;

                .pro-grid {
                    grid-column: 1 / -1;
                    grid-template-columns: repeat(2, 1fr);
                    gap: $xsmall;
                    display: grid;

                    @media screen and (max-width: 500px) {

                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }
        }
    }
}