.professionnel-card {
    grid-column: span 1;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 9px;
    border: 1px solid $grey-selection;

    background: linear-gradient(98deg, #ffffff 9.02%, #ffffff 97.38%, #ffffff 99.42%);

    transition: 150ms ease-in-out;

    .slick-slider {
        width: 100%;
        height: 350px;

        .slick-dots {
            position: absolute;
            bottom: 0px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center;
        }

        .slick-list {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .slick-next {
            right: 8px;
        }

        .slick-prev {
            left: 8px;
            z-index: 1;
        }
    }

    .txt {
        padding: 10px;
        gap: 15px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 22px;
        }

        .note {
            font-size: 22px;
        }

        .fonctions {
            display: flex;
            gap: 5px;
            display: flex;
            gap: 0px 5px;
            overflow: hidden;
            position: relative;
            width: 100%;

            p {
                color: $color-1;
                border: .5px solid $color-1;
                border-radius: 50px;
                padding: 0px 5px;
                height: fit-content;
                font-size: 14px;
                white-space: nowrap;

            }

            &::after {
                content: "";
                width: 30%;
                height: 100%;
                z-index: 2;
                right: 0;
                top: 0;
                position: absolute;
                background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.92) 30%, rgba(255, 255, 255, 0.00) 50%);
            }
        }

        .loc {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: center;

            p {
                font-size: 16px;
            }
        }

        button {
            align-self: flex-end;
        }
    }

    &:hover {
        cursor: pointer;
        background: linear-gradient(168deg, #FFF 9.02%, #F7FFFF 90.38%, #C5FFFE 99.42%);
        transition: 150ms ease-in-out;
        border: 1px solid $color-1;

        .spe {
            background: none;
            cursor: pointer;
            transition: 150ms ease-in-out;

            span {
                background-color: $dark;
                left: -10px;
                width: 150%;
                transition: 150ms ease-in-out;
            }
        }
    }

    .slick-slide {
        height: 100%; /* Hauteur de 100% pour occuper toute la hauteur du conteneur */
        display: flex; /* Utilisez flexbox pour centrer le contenu verticalement */
        justify-content: center; /* Centre le contenu horizontalement */
        align-items: center; 
        width: inherit;/* Centre le contenu verticalement */
    }
    
    .slick-slide img {
        width: 100%; /* Assurez-vous que l'image occupe toute la largeur du conteneur */
        height: auto; /* Empêche le débordement de l'image si elle est plus grande que le conteneur */
        object-fit: cover; /* Similaire à l'effet "object-fit: cover" pour couvrir le conteneur */
    }

    .prestations{
        height: 350px;
        position: absolute;
        width: 100%;
        display: flex;
        top: 0;
        gap: 5px;
        padding: 10px;
        justify-content: flex-end;
        p{
            color: $color-1;
            font-size: 14px;
            font-weight: 600;
        }
        .prestaap{
            display: flex;
            justify-content: space-between;
            background-color: $white;
            border: 1px solid $color-2;
            gap: 5px;
            padding: 5px;
            border-radius: 9px;
            &.titre{
                border:none;
                 border-bottom: 1px solid $grey-selection;
                
                p{
                    color: black;
                }
            }
        }
    }
    @media screen and (max-width: 768px){

        .txt{
            h3{
                font-size: 18px;
            }
            .note{
                font-size: 18px;
            }
            .fonctions{
                p{
                    font-size: 12px;
                }
            }
            .loc{
                p{
                    font-size: 14px;
                }
            }
        }
        
    }
}