.offer{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 30px;
    .offer-header{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3{
            font-size: 42px;
            color: $color-1;
            font-family: $font-2;
            width: 100%;
        }
        .price{
            font-size: 64px;
            color: $color-1;
            font-family: $font-2;
            .per-month{
                font-size: 34px;
                color: $color-1;
                background-color: transparent;
            }
        }
        .oldprice{
            font-size: 34px;
            color: $blackL;
            font-family: $font-2;
            
            &.bar{
                text-decoration: line-through;
            }
        }
    }
    .offer-details{
        display: flex;
        flex-direction: column;
        gap: 15px;
        svg{
            width: 35px;
            flex-shrink: 0;
        }
        p{
            strong{
                font-size: 18px;
            }
            font-weight: 500
        }
        li{
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
        }
    }
    .offer-footer{

    }

}