.facture {
    background-color: $grey-back;
    min-height: 100vh;

    .adv{
        padding: 10px;
    }

    header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 40px;
        max-width: 800px;
    }

    .content {
        min-height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn-front.svg {
        height: 28px;
        width: 40px;
        background-color: transparent;
        padding: 0px;
    }

    .invoice {
        grid-column: 1/-1;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 40px;
        max-width: 800px;
        gap: 30px;
        box-shadow: 0 4px 40px 0 #00000040;

        p {
            font-size: 14px;
        }

        .reglement{
            grid-column: 1/-1;
        }
        span {
            grid-column: 1/-1;
        }

        .droite {
            grid-column: 1/-1;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .tot {
            grid-column: 1/-1;
            display: flex;
            gap: 10px;
            justify-content: end;
        }

        h4 {
            text-align: right;
            padding-right: 40px;
            font-size: 20px;
        }

        h5 {
            text-align: right;
            padding-right: 40px;
            font-size: 16px;
        }

        .invoice-logo {
            display: flex;
            align-items: center;
            gap: 20px;
        }



        h3 {
            grid-column: 1/-1;
            text-align: left;
        }

        .prestas {
            grid-column: 1/-1;
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            flex-direction: column;
            gap: 10px;



            .presta {
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                display: flex;
                background-color: white;
                border-radius: 9px;
                transition: 200ms ease-in-out;
                border: $grey-border 1px solid;

                p {

                    flex: 1;
                }

                &.head {
                    border-radius: none;
                    border: transparent 1px solid;
                    border-bottom: $grey-border 1px solid;

                    p {
                        color: $grey-writted;
                    }
                }

                svg {
                    width: 0px;
                    transition: 150ms ease-in-out;
                }

                .nom {
                    align-self: stretch;
                    display: flex;
                    flex: 3;
                    align-content: center;
                    align-items: center;
                    font-size: 16px;
                    gap: 10px;

                    p {
                        font-size: 16px;
                    }

                    span {
                        height: inherit;
                        margin-left: 10px;
                    }
                }

                .temps {
                    color: $grey-writted;
                }

                &.select {
                    border: $color-1 1px solid;

                    &:hover {

                        cursor: inherit;

                    }
                }

            }


            .titre {
                padding: 5px 10px;
                border-radius: 9px;
                gap: 10px;
                align-self: stretch;
                align-items: center;
                display: flex;
                transition: 200ms ease-in-out;
            }
        }

        .bottom {
            background-color: $color-1;
            color: white;
            padding: 10px;
            grid-column: 1/-1;
        }

        .line {
            display: flex;
            gap: 5px;
        }

        .invoice-professional-info,
        .invoice-details {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 5px;
            text-align: right;
        }
    }
}