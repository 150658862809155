.header {
    border-bottom: 1px solid $grey-border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    background-color: $white;
    width: 100%;
    top: 0;
    z-index: 1000;

    .contentheader {
        display: flex;
        margin: auto;
        width: 100%;
        height: 110px;
        max-width: $max-w;

        .logo {
            width: 20%;
            max-width: 200px;
            padding: 10px 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .btn-base {
            p {
                font-size: 16px;

            }
        }

        .nav {
            width: 100%;
            flex: 1 1;
            gap: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 $xsmall;

            .mainnav,
            .topnav {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .mainnav {
                ul {
                    display: flex;
                    gap: 30px;

                    li {
                        transition: 100ms ease-in-out;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51' height='22' viewBox='0 0 51 22' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.3365 21.5L7.62939e-06 21.5L6.71146e-06 0.50004L50.0594 0.500038L22.3365 21.5Z' fill='%23ABF1F0'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        display: flex;
                        background-position: 10px center;

                        a {
                            font-size: 16px;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 2px;
                            color: black;
                        }
                    }

                    li.active {
                        transition: 100ms ease-in-out;
                        background-position: left center;

                        a {
                            background-color: transparent;
                            color: black;
                            font-family: $font-2 ;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 800;
                        }
                    }

                    li:not(.active):hover {

                        transition: 100ms ease-in-out;
                        background-position: left center;

                        a {
                            font-size: 16px;
                            background-color: transparent;
                            color: black;
                        }
                    }
                }
            }

            nav {
                display: flex;
                gap: 15px;
            }
        }
    }

    .mobileheader {
        display: none;
    }

    .notifs {
        width: fit-content;
    }

    .leftMobileMenu {
        display: none;
    }

    @media screen and (max-width: 1024px) {

        background-color: white;

        .mobileheader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            background-color: transparent;
            z-index: 1;
            img {
                width: 50px;
            }

            .btnmenu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: fit-content;
                padding: 10px;
                background-color: $white;
                border-radius: 9px;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }


        }

        .contentheader {
            display: none;
        }
        .leftMobileMenu {
            box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
            height: 100%;
            top: 0;
            bottom: 0; 
            width: fit-content;
            display: flex;
            flex-direction: column;
            position: fixed;
            gap: 30px;
            padding: 20px 20px 40px 20px;
            background-color: white;
            justify-content: space-between;
            transition: 300ms ease-in-out;
            .btnmenu {
                padding: 10px 10px;
            }
            &.closed {
                width: 0px;
                left: -100%;
                transition: 300ms ease-in-out;
                nav, a{
                    opacity: 0;                     left: -100%;
                    left: -100%; 
                transition: 100ms ease-in-out;
                }
            }
            nav{
                
                transition: 250ms ease-in-out;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
    
                li {
                    a {
                        font-size: 18px;
                        color: black;
                        transition: 250ms ease-in-out;
                    }
    
                    &.active {
                        a {
                            font-family: $font-2 ;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 800;
                        }
                    }
                }
            }
    
            nav {
                display: flex;
                flex-direction: column;
                gap: 15px;
                transition: 100ms ease-in-out;
    
            }
        }
    }




}