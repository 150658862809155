.mapage {
    .tsave {
        display: flex;
        justify-content: space-between;
        width: 100%;
        
    }
    form {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
        overflow: scroll;
        .actions {
            display: flex;
            flex-direction: column;
            position: sticky;
            gap: 15px;
            top: 0px;
            overflow: visible;
            height: fit-content;
            border: $color-2 2px solid;
            border-radius: 15px;
            padding: 15px;
        }
        label,
        h5 {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 10px;
        }
        .form-group.drag .flex-h{
            flex-direction: row;
            background-color: $grey-back;
            border-radius: 15px; 
            align-items: center;
            gap: 5px;
            img{
                width: 30%;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 15px;
                padding: 5px;
            }
            .action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;    
                justify-content: space-evenly;
                flex: 1 0;
                gap: 15px;
                p{
                    font-size: 18px;
                }
            }
            svg{
                max-width: 30%;
                padding-right: 15px;
            }
            textarea{
                width: 100%;
            }
        }
        .form-group{
            padding: 10px 15px;
        }
        .galerie{
            h5{
                padding: 15px;
            }
        }
    }

    .profe {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        padding-bottom: 15px;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            border: 1px solid $color-2;
            border-radius: 9px;

            svg {
                width: 40px;
            }

            p {
                font-weight: 600;
                color: black;
                font-size: 20px;
            }
        }
    }

    .res {
        display: flex;
        padding: 5px;
        align-items: center;
        gap: 20px;
        align-self: stretch;
        border-radius: 7px;

        input {
            display: flex;
            padding: 10px;
            align-items: flex-start;
            gap: 10px;
            flex: 1 0 0;
        }
    }

    .left {
        gap: 0px;
        width: 600px;
        padding: 0px;
        border: 1px solid $grey-back;
        border-radius: 15px;
    }
    .right{
        min-width: 200px;
        border: 1px solid $grey-back;
        border-radius: 15px;
    }
}