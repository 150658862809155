.settings {
  .form-group.tva {
    display: flex;
    border-radius: 9px;
    align-items: center;
    gap: 15px;
    flex-direction: row;
    border: 1px solid $grey-border  !important;
    p{
      text-align: left;
    }
    padding: 10px;
    .simpleswitch { 
      width: 50px !important;
      min-width: 50px;
    }
    h4 {
      text-align: left;
      margin-bottom: 0px;
      width: fit-content;
    }
    .form-group {
        text-align: left;
        margin-bottom: 0px;
        width: fit-content!important;
      }
    span {
      margin-bottom: 0px;
    }
  }
  & > * {
    max-width: 700px;
    width: 100%;
  }
  .tsave {
    position: sticky;
    top: -$small;
    background-color: $grey-light;
    padding: 15px 0px;
    z-index: 100;
  }
  .actions {
    display: flex;
  }
  .alert {
    padding: 15px;
    border-radius: 9px;
    background-color: $grey-back;
    border: 2px solid $color-alert;
    margin-bottom: 15px;
    p {
      font-size: 18px;
    }
  }
  form {
    h3 {
      text-align: left;
      font-size: 24px;
    }
    span {
      margin-bottom: 15px;
      grid-column: 1 / -1;
    }
    h5 {
      text-align: center;
      font-size: 18px;
      grid-column: 1 / -1;
    }
    .small {
      border: none;
    }
    .plans {
      padding: 15px;
      grid-column: 1 / -1;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .it {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 9px;
        background-color: $grey-back;
        width: 100%;
        max-width: 500px;
        border: 3px solid $grey-back;
        &:hover {
          border: 3px solid #006963;
          cursor: pointer;
        }
        h3 {
          font-size: 18px;
          margin-bottom: 0px;
        }
        p {
          white-space: nowrap;
        }
        &.selected {
          border: 3px solid #006963;
        }
      }
    }
  }
}
