.rayon{
    input[type="range"] {
        width: 100%;
        max-width: 500px;
        background-color: $color-2;
        color: $dark;
    }
    input[type='range']::-webkit-slider-thumb {
        background-color: $dark;
      }
      .actions {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        position: sticky;
        gap: 15px;
    }
}