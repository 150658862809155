.marketplace{
    margin-top: 120px;
    min-height: calc(100vh - 200px);
    flex-direction: column; 
    justify-content: flex-start;
    h2 {
        grid-column: 1 / -1;
        font-size: 42px;
        font-weight: 400;
        width: 90%;
        margin: 0px auto;
        span {
            font-size: 58px;
            font-weight: 900;
        }
        padding-bottom: $small;
    }
    .contentfront{
        padding-top: $small;
        align-items: start; 
         margin: 0px auto;
         margin-bottom: $small;
       
        .left{
            grid-column: 1/3;
            padding-right: $xsmall;
        }
        .right{
            background-color: $grey-back;
            grid-column: 3/ -1;
            padding: $xsmall;
            border-radius: 9px;
            .pro-grid{
                grid-column: 1 / -1;
                grid-template-columns: repeat(3, 1fr);
                gap: $xsmall;
                display: grid;
    
            }
        }
    }
    @media screen and (max-width: 768px) {

        margin-top: 70px;
        .contentfront{
            h2 {
                font-size: 32px;
                span {
                    font-size: 48px;
                }
            }
            .left{
                grid-column: 1 / -1;
                padding-bottom: 15px;
            }
            .right{
                grid-column: 1 / -1;
                .pro-grid{
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        
    }
}