.horairesform {
    background-color: $grey-coloured;
    border-top: 1px solid $color-1;
    border-radius: 7px;
    gap: 5px; 
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 5px;

    button {
        position: sticky;
        bottom: 0;
    }

    .jour {

        grid-column: 1/-1;
        width: 100%;
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 5px;
        align-self: stretch;
        background: #FFF;
        justify-content: space-between;
        border-bottom: $grey-back 1px solid;
        border-radius: 7px;

        .periode {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            width: 300px;

        }

        .heures {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;

        }

        label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }
    }

    .react-datepicker-wrapper input {
        width: 60px;
    }
}