.accueilpro {
    flex-direction: column;

    .cont {
        padding: $small;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: $xsmall;
        overflow: auto;

       

        .disp {
            flex: 1 1;
            height: 100%;
            overflow: auto;
            display: flex;
            width: 100%;
            gap: $small;

            .list {
                flex: 1 1;
                overflow: auto;
                height: 100%;
                width: 30%;
                display: flex;
                flex-direction: column;

            }

            .end {
                border-radius: 9px;
                border: 1px solid $grey-border;
                display: flex;
                padding: 20px;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                align-self: stretch;
                margin-bottom: $xsmall;
                .item {
                    display: flex;
                    padding: 10px 15px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    border-radius: 9px;
                    background: #F4F4F4;

                    span {
                        border-radius: 11px;
                        background: #FFF;
                        padding: 3px 10px;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                    }
                }

                width: 100%;

                desc {
                    font-size: 16px;
                }

                .profile-evaluations {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-evenly;

                    p:nth-of-type(2) {
                        font-weight: 600;
                        font-size: 16px;
                    }

                    .left {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 10px;

                        p {
                            text-align: center;
                        }

                        svg {
                            height: 25px;

                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        svg {
                            height: 15px;

                        }
                    }

                    .rating {
                        display: flex;
                        gap: 7px;
                        justify-content: start;
                        align-items: center;

                    }

                }
            }

            h3 {
                padding-bottom: 15px;
            }
        }

    }

    @media screen and (max-width:768px ) {
        .cont .disp h3 {
             display: none;
        }
        .cont{
            height: calc(100vh - 50px);
        }
        .cont .disp .list {
            width: 100%;
            display:  none;
            &.act {
                display: flex;
            }
        }
    }
}