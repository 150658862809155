form, .form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    h3, h4{
        grid-column: 1/-1;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
    }

    .info{
        grid-column: 1/-1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        border-radius: 9px;
        border: 1px solid $color-2;
        font-size: 20px;
        background: #FFF;
        .form-group{
            padding: 0px;
        }
    }

    p{
        grid-column: 1/-1;
        text-align: center;
        &.left{
            text-align: left; 
        }
    }
    .btns{
        grid-column: 1/-1;
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        grid-column: span 2;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        
        .full {
            grid-column: 1/-1;
        }  
    }
    .desc {
        color: #787878;
        font-size: 12px;
    }
    .form-group, .StripeElement{
        grid-column: 1/-1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px;
        width: 100%;
        position: relative;
        .form-group{
            
        padding: 0px;
        }
        &.line{
         grid-column: 1 span;
        }
        &:hover{
            background-color: $grey-back;
            border-radius: 7px;
        }

        &.check{
            display: flex;
            flex-direction: row;
            gap: 5px;
            width: 100%;
            position: relative;
            justify-content: center;
        }
        
        .listad{
            width: 100%;
            height: 70px;
            position: relative;
            ul{
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: scroll;
                li{
                    width: 100%;
                    padding: 5px;
                    &:hover{
                        background-color: white;
                        cursor: pointer;
                        border-radius: 2px;
                    }
                }
            }
        }
    }.form-tow {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        grid-column: span 2;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        .form-group{
            grid-column: span 1;

            padding:10px 0px;
        }
    }
    label, h5{
        color: black;
        font-size: 16px;
        font-weight: 500;
    }
        
    .inputs {
        padding: 10px 10px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 9px;
        border: 1px solid $color-2;
        background: #FFF;    
        font-family: "Inter", sans-serif;

        &.mid {
            padding: 5px 5px;
            text-align: center;
        }
        &.small {
            border-radius: 3px;
            padding: 1px 4px;
        }
        &:focus{ 
            border: 1px solid $color-1;
        }
    }
    textarea{
        height: 150px;
    }

    &.suppr{
        .inputs {
            padding: 10px 10px;
            font-size: 16px;
            font-weight: 500;
            border-radius: 9px;
            border: 0.5px solid transparent;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
            pointer-events: none;
        
            &.small {
                border-radius: 3px;
                padding: 1px 5px;
            }
        }
    }
    .error{
        color: $color-alert;
        font-size: 12px;
    }
    .bigerror{
        color: $color-alert;
        font-size: 16px;
        font-weight: 600;
    }
    &.mid {
        gap: 5px;
    }

    .drop{
        border: 1px dashed #25AAA5;
        cursor: pointer;
        background-color: white;
        height: 150px;
        overflow: scroll;
        max-width: 100%;
        margin: 15px;
        border-radius: 5px;
        position: relative;
        padding: 5px;
        transition: 150ms ease-in-out;
        &:hover{
            border: 3px dashed $color-1;
            transition: 150ms ease-in-out;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            align-content: flex-start;
            gap: 5px;
            padding: 10px;
            li{
                width:  fit-content;
                height: fit-content;
                background-color: $grey-back;
                padding: 5px;
                border-radius: 9px;
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;
                border: solid 1px $grey-border;
                &:hover{
                    background-color: white;
                    cursor: pointer;
                    border-radius: 2px;
                }
            }
        }
    }

    .profe {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        padding-top: 15px;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            border: 4px solid $grey-border;
            border-radius: 9px;
            background-color: $grey-back;
            transition: 150ms ease-in-out;
            img {
                width: 40px;
                opacity: 0.4;
                transition: 150ms ease-in-out;
            }

            p {
                font-weight: 600;
                color: black;
            }
            &.active{
                border: 4px solid $color-1;
                background-color: white;
                transition: 150ms ease-in-out;
                img {
                    opacity: 1;
                    transition: 150ms ease-in-out;
                }
            }
            &:hover{
                cursor: pointer;
                border: 4px solid $color-2;
                background-color: white;
                transition: 150ms ease-in-out;
                img {
                    opacity: 1;
                    transition: 150ms ease-in-out;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {

         padding: 10px;
        .form-group.line{
            grid-column: 1 / -1;
        }
        .btns{
            grid-column: 1 / -1; 
            grid-template-columns: repeat(1, 1fr);
        }
    }
}