.agenda {
    flex-direction: column;

    .calList {
        display: flex;
        flex-direction: column;
        gap: 10px; 
        border-radius: 7px; 
        height: 100%;
    }

    .left {
        width: 400px;

        &.horaires {
            width: 500px;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
        position: relative;


    }

    .filtre {
        padding: 10px;
    }

    .datem {
        width: 100%;
        z-index: 200;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            width: fint-content;
            font-size: 18px;
            padding: 10px;
            border-radius: 9px;
            border: 1px solid #EDEDED;
        }
    }

    @media screen and (max-width: 768px) {
      .left.horaires{
        width: 100%;
      }
    }
    
}