.prestations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &>*{
        max-width: 1000px;
        width: 100%;
    }
    .actions{
        display: flex;
        gap: 15px;
    }
    .draglist {
        display: flex;
        align-items: stretch;   
        justify-content: stretch;
        padding: 12px 14px;
        flex-direction: column;
        gap: 15px;
        border-radius: 9px;
        border: 1px solid $grey-border;
        background: $grey-back;
        .list{
            display: flex;
            align-items: stretch;   
            justify-content: stretch;
            padding: 12px 14px;
            flex-direction: column;
            gap: 15px;
        }
        .inputs {
            font-size: 15px;
        }
        .presta {
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            display: flex;
            background-color: white;
            border-radius: 9px;
            transition: 200ms ease-in-out;
            .nom{
                align-self: stretch;
                display: flex;
                flex: 1 0;
                align-content: center;
                align-items: center;
            }
            .temps{
                color: $grey-writted;
            }
        }
        .titre{
            padding: 5px 10px;
            border-radius: 9px;
            gap: 10px;
            align-self: stretch;
            align-items: center;
            display: flex;
            transition: 200ms ease-in-out;
            background-color: $grey-back;
        }

        .sortable-ghost{
            .titre, .presta{
              border: $color-1 solid 1px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                transition: 200ms ease-in-out;
            }
        }
    }
}