.notifPage {
    position: fixed;
    right: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    .back {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.71);
    }

    &.nopen {
        width: 0%;

        .content {
             transform: translateX(100%);
            opacity: 0;
        }
    }

    .content {
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: 100%;
        background-color: white;
        position: absolute;
        right: 0;
        width: 400px;
        padding: 20px;
        gap: $xsmall;
        transition: 150ms ease-in-out;

        .top {
            display: flex;
            gap: 15px;
            width: 100%;
            justify-content: start;
            align-items: center;

            a {
                display: flex;
                justify-content: center;
                height: fit-content;
                background-color: transparent;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .notifList {
            display: flex;
            padding: 10px;
            height: 100%;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            align-self: stretch;
            overflow: scroll;
            border-radius: 9px;
            border: 1px solid #D9D9D9;
            background: radial-gradient(50% 50% at 50% 50%, #DDFDFC 0%, rgba(217, 217, 217, 0.29) 100%);
            
            .itemnotif {
                width: 100%;

                p {
                    font-size: 16px;
                    gap: 15px;
                }
            }
        }
    }

    @media screen and (max-width:768px ) {
        .content {
            width: 100%;
        }
    }
}