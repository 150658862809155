.moncompte {
    padding-top: 120px;
    padding-bottom: $small;
    height: 100vh;
    position: relative;

    .contentfront {
        padding-top: $small;
        align-items: start;
        height: 100%;
        position: relative;

        .flex-h.clients{
            grid-column: 3/-1;
            .right {
                display: flex;
                justify-content: center;
            }
        }

        h3 {
            margin-bottom: $small;
        }

        .left {
            grid-column: 3/7;
            padding-right: $xsmall;

            overflow: auto;
            height: 100%;
            display: flex;
            flex-direction: column;

        }

        .right {
            grid-column: 7/ -1;
            border-radius: 9px;

            form {
                border: 1px solid #D9D9D9;
                border-radius: 9px;
                padding: $xsmall;
            }
        }

        .filtre {
            gap: 15px;

            grid-column: 1/ 3;
            padding-right: $small;

            .desc {
                font-size: 22px;
                color: black
            }

            a {
                font-size: 18px;
            }

            .deconnexion {
                font-size: 18px;
                color: red;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        padding-top: 70px;

    }

    @media screen and (max-width: 768px) {
        height: fit-content;

        .contentfront {
            grid-template-columns: 1fr;

            .left {
                grid-column: 1/ -1;
                padding-right: 0;
                padding: 20px 0px;
                height: 50vh;
            }

            .right {
                grid-column: 1/ -1;

                padding: 20px 0px;

                form {
                    padding: 10px;
                }
            }

            .filtre {
                grid-column: 1/ -1;
                padding-right: 0;
            }

            .flex-h.clients{
                grid-column: 1/-1;
                flex-direction: column;
                .left {
                    height: fit-content;
                }
                .right {
                    display: flex;
                    justify-content: center;
                    padding: 0px;
                }
                .clientlist{
                    padding: 10px;
                    .client{
                        border-bottom: 1px solid #D9D9D9;   
                        overflow: hidden;
                    }
                    p{
                        font-size: 11px;
                        font-weight: 600;
                    }
                }
            }
        }
        
    }
}