.rdvlist {
        display: flex;
        padding: 10px;
        height: 100%;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 15px;
        flex: 1 0 0;
        align-self: stretch;
        overflow: scroll;
        border-radius: 9px;
        border: 1px solid #D9D9D9;
        background: radial-gradient(50% 50% at 50% 50%, #DDFDFC 0%, rgba(217, 217, 217, 0.29) 100%);

        .empty {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
        }

        &::-webkit-scrollbar {
                width: 2px;
                height: 2px;
        }

        &::-webkit-scrollbar-thumb {
                background: $grey-writted;
        }

        .displist {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 15px;
        }

        .date {
                display: flex;
                width: 100%;

                p {
                        width: 100%;
                        white-space: nowrap;
                        padding: 0px 10px;
                        text-align: center;
                }

                span {
                        background-color: $grey-strong;
                }
        }

        .stickyDate {
                position: -webkit-sticky;
                /* Pour la compatibilité avec Safari */
                position: sticky;
                top: -11px;
                /* Définissez la distance depuis le haut de la fenêtre */
                background-color: $grey-back;
                /* ou toute autre couleur de fond */
                z-index: 100;
                /* Assurez-vous qu'il reste au-dessus des autres contenus */
                padding: 5px;
                /* Ajoutez du padding si nécessaire */
        }
}