.side-menu {
    position: relative;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: white;
    transform: translateX(0);
    transition: transform 0.3s ease;
    padding: 0px 20px 33px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-right: 1px solid #EDEDED;
    display: flex;
    justify-content: space-between;

    h3 {
        display: flex;
        height: 55px;
        flex-direction: column;
        justify-content: center;

    }

    .mobileBtn {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    nav {
        width: 100%;


        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            li {
                display: flex;
                width: 100%;
                align-items: center;
                border-radius: 9px;
                background: #FFF;
                transition: none;

                &.active {
                    a {
                        border: 0.5px solid #006963;
                        font-weight: 500;
                        color: $color-1;
                        transition: none;

                        path {
                            fill: $color-1;
                        }
                    }
                }

                a {
                    background: white;
                    color: black;
                    padding: 5px 10px;
                    width: 100%;
                    display: flex;
                    border-radius: 9px;
                    gap: 14px;
                    border: 0.5px solid white;
                    align-items: center;

                    transition: none;

                    &:hover {
                        border: 0.5px solid $color-1;
                        font-weight: 500;
                        color: $color-1;

                        transition: none;

                        path {
                            fill: $color-1;
                        }
                    }

                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    img {
        align-self: center;
        max-width: 100px;
    }

    .statut {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        width: 100%;
        border-radius: 9px;
        padding: 10px 10px;
        border-left: 3px solid $color-2;

        .head {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            h4 {
                font-size: 18px;
                font-weight: 600;
                color: $color-1;
            }

            svg {
                fill: $color-2;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
        }

        &.alert {
            border-left: 3px solid $color-alert;

            .head {
                svg {
                    fill: $color-alert;
                }

                h4 {
                    color: $color-alert;
                }
            }
        }
    }

    .body-statut {
        border-top: 1px solid #EDEDED;
        padding-top: $xsmall;

        .alert {
            margin-top: 10px;
            border: 1px solid $color-alert;
            padding: 5px;
            border-radius: 9px;
        }

        ul {
            padding-top: 10px;

            a {
                color: $color-alert;
                font-weight: 500;
                font-size: 16px;
                text-decoration: underline;
                display: flex;
                gap: 10px;
                align-items: center;
                padding: 5px 0;
                transition: none;

                &:hover {
                    color: black;
                }

                svg {
                    fill: $color-1;
                }
            }
        }

        .btn-pro {
            margin: 10px 0px 0px 0px;
        }

        button {
            display: flex;
        }
    }

}
.side-menu.open {
    transform: translateX(0);
}

.mobileBtn {
    display: none;
}

@media screen and (max-width:768px ) {
    .side-m.open {
        transform: translateX(0);
        width: fit-content;
    }
    .side-m{
        width: 0px;
        z-index: 1000;
    }

    .side-menu{ 
        position: absolute;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.16); 
        transform: translateX(-100%);
        nav{
            margin-top: 60px;
            ul li a {
                font-size: 18px;
            }
            .btn-base p{
                margin-top: 15px;
                font-size: 18px;  
            }
        }
    }
    
    
    .mobileBtn {
       position: absolute;
       z-index: 1000;
       height: 50px;
       width: 50px;
       padding: 15px;
       display: flex;
        align-items: center;
        justify-content: center;
    
       background-color: white;
    }
}

