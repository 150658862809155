.dateSelect{
    display: flex;
    flex-direction: column;
    gap: 15px;

    .title{
        display: flex;
        gap: 5px;
        align-items: center;
        p{
            font-size: 16px;
            font-weight: 600;
            &.desc{
                font-weight: 400;
            }
        }
    }
    .week-days{
        display: flex;
        gap: 15px;
        align-items: center;

        .btn-base{
            padding: 5px;
        }
        .day{
            width: 110px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            gap: 5px;
            background-color: $grey-back;
            border-radius: 9px;
            cursor: pointer;
            transition: 200ms;
            border: solid 2px $grey-back;
            h5{
                font-weight: 500;
                font-size: 16px;
            }
            &:hover{
                transition: 200ms;
                background-color: $color-1;
                color: white;
            }
            &.active{
                transition: 200ms;
                background-color: $color-1;
                color: white;
            }

            &.ferme{
                transition: 200ms;
                background-color: transparent;
                color: $grey-writted;
                border: solid 2px $grey-border;
                cursor: default;
            }

        }

    }
    .time-slots{
        display: flex;
        flex-direction: column;
        gap: 5px;
        .plages{
           padding: 5px;
           border-radius: 9px;
            background: linear-gradient(90deg,$grey-back 0%, rgba(255, 255, 255, 0) 100%);
            display: flex;
            gap: 15px;
            justify-content: start;
            align-items: center;
            transition: 200ms ease-in-out;
            &:hover{
                background: linear-gradient(90deg,$grey-back 20%, rgba(255, 255, 255, 0) 100%);
                transition: 200ms ease-in-out;
            }
            h5{
                width: 110px;
                min-width: 110px;
                padding-left: 10px;
            }
            .listh{    
                display: flex;
                gap: 5px;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .btn-h{
                    width: 70px;
                    border-radius: 9px;
                    border: solid 1px $color-2;
                    background-color: white;
                    padding: 9px;
                    p{
                        
                    font-size: 18px;
                    }
                    &:hover{
                        transition: 200ms;
                        background-color: $color-1;
                        color: white;
                    }
                    &.active{
                        transition: 200ms;
                        background-color: $color-1;
                        color: white;
                    }
                }
            }
        }
    }
}