.searchbar{
    display: flex;
    padding: 2px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    border: 1px solid #000;
    width: 70%;
    max-width: 700px;
    font-size: 22px;
    position: relative;
    input{
        border: none;
        flex: 1;
        font-size: 16px;
        padding-left: 15px;
    }
    .suggest{
        position: absolute;
        top: 35px;
        left: 5px;
        background: rgb(255, 255, 255);
        border-radius: 9px;
        box-shadow: -2px 14px 20px 0px rgb(0 0 0 / 11%);
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                padding: 10px;
                font-size: 16px;
                border-radius: 9px;
                cursor: pointer;
                &:hover{
                    background-color: $grey-back;
                }
            }
        }
    }
    select{
        border: none;
        font-size: 16px;
    }
}