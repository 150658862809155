.profile-card {
    .pro {
        margin-top: 120px;

        h4 {
            font-size: 18px;
            font-weight: 700;
        }


        .contentfront {
            gap: $xsmall;
            align-items: start;

            .borderpro {
                display: flex;
                padding: 6px 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 9px;
                border: 0.2px solid #787878;

                svg {
                    width: 18px;
                    transition: 150ms ease-in-out;
                }

                &.btn {
                    &:hover {
                        cursor: pointer;
                        border: 0.2px solid $color-1;

                        p {
                            color: $color-1;
                        }

                        svg {
                            transform: scale(1.2);
                            transition: 150ms ease-in-out;
                        }
                    }
                }
            }

            .profile {
                grid-column: 1 / 5;
                min-width: 400px;
                top: calc(110px + $small);
                padding: $xsmall 0;
                gap: $xsmall;
                display: flex;
                flex-direction: column;
                height: 100%;

                &.resa {
                    opacity: .5;
                }

                .body {
                    border-radius: 9px;
                    border: 1px solid #E6E6E6;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 25px;
                    padding: $xsmall ;
                    background-color: white;
                    box-shadow: 0px -50px 0px 0px #FFF;
                }

                .stick {
                    position: sticky;
                    top: calc(110px + $small);
                    bottom: 150px;
                    z-index: 10;
                }

                h2 {
                    font-size: 20px;
                }

                .btn-front p {
                    font-size: 14px;
                }

                .line {
                    height: 1px;
                    width: 100%;
                    background-color: $grey-border;
                }

                .info1 {
                    display: flex;
                    gap: $xsmall;

                    .left {
                        width: 30%;
                        height: 100%;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            aspect-ratio: 1;
                            border-radius: 9px;

                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-evenly;
                        gap: 10px;

                        p {
                            font-size: 16px;
                        }

                        .fonctions {
                            display: flex;
                            gap: 5px;
                            display: flex;
                            flex-wrap: wrap;
                            position: relative;
                            width: 100%;

                            p {
                                color: $color-1;
                                border: .5px solid $color-1;
                                border-radius: 50px;
                                padding: 0px 5px;
                                height: fit-content;
                                font-size: 14px;
                                white-space: nowrap;

                            }
                        }

                        .ville {
                            svg {
                                width: 15px;
                            }

                            p {
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                            }
                        }
                    }
                }


                .justify {
                    gap: 5px 15px;
                    flex-wrap: wrap;
                    justify-content: start;

                    svg {
                        height: 25px;

                    }
                }

                .notes {
                    width: 100%;

                    desc {
                        font-size: 16px;
                    }

                    .profile-evaluations {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-evenly;

                        p:nth-of-type(2) {
                            font-weight: 600;
                            font-size: 16px;
                        }

                        .left {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 10px;

                            p {
                                text-align: center;
                            }

                            svg {
                                height: 25px;

                            }
                        }

                        .right {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;

                            svg {
                                height: 15px;

                            }
                        }

                        .rating {
                            display: flex;
                            gap: 7px;
                            justify-content: start;
                            align-items: center;

                        }
                    }
                }

                .opening-hours {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;

                    .desc {
                        align-self: flex-start;
                    }

                    ul {
                        display: grid;
                        width: 100%;
                        grid-template-columns: repeat(2, 1fr);
                        flex-wrap: wrap;
                        gap: 15px;
                        padding: 15px;
                        justify-content: flex-start;

                        li {
                            border-radius: 7px;
                            background: $grey-back;
                            padding: 5px 7px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    strong {
                        font-size: 16px;
                    }
                }
            }

            .profile-body {
                grid-column: 5 / -1;
                padding: $xsmall;
                display: flex;
                flex-direction: column;
                gap: $xsmall;
                margin-bottom: $small;
                

                &.resa {
                    border: 1px $color-1 solid;
                    margin-top: $xsmall;
                    border-radius: 9px; 
                    .add {
                        overflow: hidden;
                        height: 0px;
                        padding: 0px;

                        &.open {
                            overflow: scroll;
                            height: 200px;
                            padding: 15px;
                        }
                    }
                }

                h3 {
                    padding-bottom: 12px;
                }


                .portfolio-gallery {
                    display: grid;
                    grid-template-columns: repeat(8, 1fr);
                    align-items: center;
                    gap: $xsmall;
                    height: 400px;

                    h3 {
                        grid-column: 1 / -1;
                    }

                    div {

                        position: relative;
                        height: 100%;
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .photo1 {

                        grid-column: 1 / 5;
                        grid-row: 1/3;

                        button {
                            border: none;
                            background-color: $white;
                            aspect-ratio: 1;
                            border-radius: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 7px;
                        }

                        .back {
                            z-index: 10;
                            position: absolute;
                            left: 0;
                            bottom: 50%;
                            transform: translate(-50%, 50%);
                        }

                        .next {
                            z-index: 10;
                            position: absolute;
                            right: 0;
                            bottom: 50%;
                            transform: translate(50%, 50%);
                        }
                    }

                    .photo2 {

                        grid-column: 5 / 7;
                        grid-row: 1/2;
                        height: 100%;
                    }

                    .photo4 {

                        grid-column: 5 / 7;
                        grid-row: 2/3;
                        height: 100%;
                    }

                    .photo3 {

                        grid-column: 7 / -1;
                        grid-row: 1/2;
                        height: 100%;
                    }

                    .photo5 {

                        grid-column: 7 / -1;
                        grid-row: 2/3;
                        height: 100%;
                    }
                }

                .prestations {
                    button {
                        width: fit-content;
                        margin-left: 15px;
                        align-self: center;

                        svg {
                            width: 10px;
                            transition: 150ms;
                        }

                        &.open {
                            svg {
                                transform: rotate(180deg);
                                transition: 150ms;
                            }
                        }
                    }

                    &.small {
                        ul {
                            gap: 2px;
                            padding: 15px 15px 0px 15px;

                            .presta {
                                &.select {
                                    border: 1px solid $grey-border;

                                    &:hover {
                                        cursor: inherit;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        display: flex;
                        align-items: stretch;
                        justify-content: stretch;
                        flex-direction: column;
                        gap: 10px;
                        padding: 15px;



                        .presta {
                            padding: 10px 20px;
                            justify-content: center;
                            align-items: center;
                            gap: 20px;
                            align-self: stretch;
                            display: flex;
                            background-color: white;
                            border-radius: 9px;
                            transition: 200ms ease-in-out;
                            border: $grey-border 1px solid;

                            svg {
                                width: 0px;
                                transition: 150ms ease-in-out;
                            }

                            .nom {
                                align-self: stretch;
                                display: flex;
                                flex: 1 0;
                                align-content: center;
                                align-items: center;
                                font-size: 16px;
                                gap: 10px;

                                p {
                                    font-size: 16px;
                                }

                                span {
                                    height: inherit;
                                    margin-left: 10px;
                                }
                            }

                            .temps {
                                color: $grey-writted;
                            }

                            &.select {
                                border: $color-1 1px solid;

                                &:hover {

                                    cursor: inherit;

                                }
                            }

                            &:hover {
                                cursor: pointer;
                                border: $color-1 1px solid;

                                svg {
                                    width: 9px;
                                    transition: 150ms ease-in-out;
                                }
                            }
                        }

                        .titre {
                            padding: 5px 10px;
                            border-radius: 9px;
                            gap: 10px;
                            align-self: stretch;
                            align-items: center;
                            display: flex;
                            transition: 200ms ease-in-out;
                        }
                    }
                }

            }

            .priseRdv {
                display: flex;
                flex-direction: column;
                gap: $small;

                &.closed>* {
                    opacity: .5;
                    pointer-events: none;

                    .formheader {
                        pointer-events: visible;
                        opacity: 1;
                    }
                }

                .connectsection {
                    border-radius: 9px;
                    border: 1px solid $grey-border;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    padding: 15px;


                    .form,
                    form {
                        label {
                            font-size: 14px;
                            color: black;
                        }

                        .check {
                            label {
                                color: black;
                            }
                        }

                        .btns {
                            margin-top: 15px;
                            display: flex;
                            justify-content: center;

                            .btn-pro {
                                width: 100%;
                                margin: 0px;
                            }
                        }
                    }

                    .login {
                        .form-group {
                            grid-column: span 1;
                        }
                    }
                }

                .btn-front {
                    width: fit-content;
                    margin: auto;

                    &.inactive {
                        opacity: .5;
                        cursor: not-allowed;
                    }
                }

                .itemresa {
                    &.small {
                        h4 {
                            opacity: .5;
                        }

                    }
                }

                .confirmation {


                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    align-items: center;
                    width: 100%;

                    p {
                        font-size: 16px;
                    }

                    .illu {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border: $color-2 1px solid;
                        padding: 15px;
                        width: fit-content;
                        border-radius: 9px;

                        &.alert {
                            border: $color-alert 1px solid;
                        }

                        svg {
                            width: 70px;
                            max-width: 70px;
                        }

                        .txt {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;

                            p {
                                font-size: 18px;
                                overflow: hidden;
                                /* Masque le texte qui déborde */
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    .infos {
                        display: flex;
                        gap: 15px;
                        width: 100%;
                        flex-wrap: wrap;
                        justify-content: center;

                        .grp {
                            flex-wrap: nowrap;
                            width: fit-content;
                            text-align: center;
                        }
                    }

                    .grp {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }

        }

        .dynamicswitch {
            display: none;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 1024px) {
        .pro {
            .dynamicswitch {
                display: flex;
                grid-column: 1 / -1;
                overflow: scroll;

                p {
                    white-space: nowrap;
                }
            }

            margin-top: 80px;

            .contentfront {
                gap: 0px;

                p {
                    font-size: 14px;
                }

                .profile {
                    grid-column: 1 / -1;
                    min-width: inherit;

                    .info1 {
                        grid-column: 1 / 5;
                        gap: 15px;

                        .right {
                            .fonctions {
                                p {
                                    font-size: 14px;
                                }
                            }

                            .ville {
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .body:not(.stick) {
                        display: none;

                        &.apmob {
                            display: flex;

                        }
                    }

                    .stick {
                        display: grid;
                        grid-template-columns: repeat(6, 1fr);
                        top: 90px;

                        .justify:not(.res) {
                            display: none;
                        }

                        .justify.res {
                            gap: 10px;
                            grid-column: 5 / -1;
                            justify-content: flex-end;

                            svg {
                                height: 30px;
                            }
                        }

                        .borderpro {
                            display: none;
                        }
                    }
                }

                .profile-body {
                    grid-column: 1 / -1;
                    gap: 30px;
                    padding: 0px;
                    padding-bottom: 30px;
                    padding-top: 10px;
                    &.resa {
                        
                    padding-bottom: 0px;
                    }

                    .portfolio-gallery {
                        padding: 0px;
                        gap: 0px;
                        padding: 0px;
                        border-radius: 15px;

                        h3 {
                            grid-column: 1 / -1;
                        } 
                    }

                    .prestations {
                        ul {
                            padding: 0px;
                            padding-top: 15px;

                            .presta {
                                padding: 7px;
                                gap: 5px;

                                .nom {
                                    p {
                                        font-size: 14px;
                                    }
                                }

                                .temps {
                                    font-size: 14px;
                                }

                                svg {
                                    display: none;
                                }

                            }
                        }
                        button {
                            width: -moz-fit-content;
                            width: fit-content;
                            margin-left: 7px;
                            align-self: center;
                        }
                    }

                    &.resa {
                        .priseRdv {
                            padding: 7px;

                            .prestations {
                                .addd {
                                    button {
                                        margin: 5px 7px;
                                    }
                                }
                            }
                        }
                    }
                }


            }
        }


    }

    @media screen and (max-width: 768px) {
         .pro .contentfront .profile-body .portfolio-gallery {
            .photo1 {
                grid-column: 1 / -1;
                grid-row: 1/3;
                border-radius: 15px 15px 0px 0px;

                button {
                    padding: 5px;
                }
            }

            .photo2 {
                grid-column: 1 / 3;
                grid-row: 3/4;
            }

            .photo4 {
                grid-column: 3 / 5;
                grid-row: 3/4;
            }

            .photo3 {
                grid-column: 5 / 7;
                grid-row: 3/4;
            }

            .photo5 {
                grid-column: 7 / -1;
                grid-row: 3/4;
            }
        }
       .pro .contentfront .priseRdv .connectsection .login .form-group {
            grid-column: span 2;  
         }
        .pro .contentfront .profile-body .prestations ul .presta {
            padding: 7px;
            gap: 5px;

            .nom {
                p {
                    font-size: 14px;
                }
            }

            .temps {
                font-size: 14px;
            }

            svg {
                display: none;
            }

            .btn-front {
                margin-left: 7px;
                padding: 5px 7px;
            }
        }

        .dateSelect {

            .week-days {
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: flex-start;
                overflow: scroll;
                padding-bottom: 5px;

                .day {
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 10px;
                    gap: 5px;
                    background-color: #F2F2F2;
                    border-radius: 9px;
                    cursor: pointer;
                    transition: 200ms;
                    border: solid 2px #F2F2F2;

                    h5 {
                        font-size: 14px;
                        white-space: nowrap;
                        padding-left: 0px;
                    }
                }
            }

            .time-slots .plages{
                flex-direction: column;
                background: linear-gradient(0deg, #F2F2F2 0%, rgba(255, 255, 255, 0) 100%);
                h5 {
                    width: fit-content;
                    min-width: inherit;
                    padding-left: 10px;
                }
                .listh {
                    display: flex;
                    gap: 5px;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    flex-wrap: wrap;
                }
            } 

        }
    }

}