.footer {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: $white;
    z-index: 500;
    background-color: $color-1;
    flex-direction: column; 
    align-items: center;

    .fb {
        display: flex;
        justify-content: center;
        border-top: 1px solid $color-2;
        align-items: center;
        gap: 15px;
        padding: 10px;
        width: 100%;
        svg {
            height: 30px;
        }
    }

    .contentfooter {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: $max-w;

        .left {
            padding: $small 0;
            display: flex;
            background-color: white;
            flex-direction: column;
            width: 20%;
            align-items: center;
            justify-content: center;
            gap: $small;

            .reseaux {
                display: flex;
                gap: 20px;

                .item {
                    height: 30px;

                    svg {
                        height: 30px;
                    }
                }
            }

            .logo {
                padding: 0px $xsmall;

                img {
                    width: 100%;
                }
            }

        }

        .right {

            padding: 2.0833333333vw;
            background-color: #006963;
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .mainnav,
            .topnav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                justify-content: center;
            }

            ul {
                display: flex;

                padding: 20px;
                gap: 30px;

                li {
                    transition: 100ms ease-in-out;
                    background-repeat: no-repeat;
                    display: flex;
                    background-position: 10px center;

                    a {
                        font-size: 16px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2px;
                        color: white;
                        background-color: $color-1;
                    }
                }

                li.active {
                    transition: 100ms ease-in-out;
                    background-position: left center;

                    a {
                        background-color: transparent;
                        color: white;
                        font-family: $font-2 ;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 800;
                    }
                }

                li:not(.active):hover {

                    transition: 100ms ease-in-out;
                    background-position: left center;

                    a {
                        font-size: 16px;
                        background-color: transparent;
                        color: white;
                    }
                }
            }

            .legaux {
                border-top: $color-2 solid 2px;
                display: flex;
                gap: 20px;
                padding: 20px;

                .op {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
            }

            p {
                color: $white;
            }

            a {
                width: fit-content;
                color: white;
                background-color: transparent;

                &.brice {
                    color: orange;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .copy {
                width: 100%;
                text-align: center;
                color: white;
            }
        }


    }

    &.pro {
        width: 100%;

        .right {
            width: 100%;
            padding: 0px;

            .legaux {
                padding: 10px 20px;
                justify-content: space-between;

                p {
                    font-size: 12px;
                }
            }
        }

    }


    @media screen and (max-width: 768px) {
        .contentfooter .right ul {
            flex-direction: column;
        }

        .contentfooter {
            flex-direction: column;

            .left {
                width: 100%;
                padding: 20px 20px;
                justify-content: space-between;
                flex-direction: row-reverse;
                display: none;

                .reseaux {
                    justify-content: center;

                    .item {
                        height: 40px;

                        svg {
                            height: 40px;
                        }
                    }
                }

                .logo {
                    width: 100px;
                }
            }

            .right {
                width: 100%;
                padding: 20px 0px;

                .legaux {
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
    }
}